/*
	  ============================================================
		   Font Color
	  ============================================================
*/
.navigation .dl-menuwrapper > ul > li a:hover,
#trigger-overlay,
.kode_text_list li a:hover,
.kode_text_list li a:before,
.breadcrumbs li strong,
.sab_banner_text li:before,
.breadcrumbs li a,
.kode_donors_text  h6,
.kode_donors_text h5 a:hover,
.kode_attorney_detail_skill.project .label,
.kode_shop_fig  .shop_icon li a,
.kode_shop_text h6 a:hover,
.kode_shop_text > h5,
.event_rating a:hover,
.kode_service_des .right_arrow.btn:hover,
.kode_service_des .right_arrow.btn i,
.kode_pricing_des:hover .kode_pricing_dollar h3,
.pricing_list li a:hover,
.pricing_list li a:before,
.kode_project_text h5 a:hover,
.kode_project_dollar  a:hover,
.kode_project_text h4 a:hover,
.kode_project_dollar  a span,
.kode_portfolio_text h4 a:hover,
.kode_portfolio_text h6 a:hover,
.kode_event_speaker_text h6 a:hover,
.event_detail_list li a i,
.kode_coming_event_text a:hover,
.kode_coming_event_text h5 a:hover,
.kode_coming_event_text > a i,
.event_studium,
.kode_event_detail .kode_calender_list .kode_event_text span a:hover,
.kode_event_detail .kode_calender_list .kode_event_text h4 a:hover,
.event_date span,
.kode_contact_text a,
.kode_404_text h2,
.siderbar_categories.archive .categories_detail  li a:hover:before,
.sidebar_add figcaption h5,
.kode_social_share > a:hover,
.kode_blog_detail_text h3 a:hover,
.categories_detail li a:hover,
.kode_pagination .next:hover,
.kode_pagination .prve:hover,
.kode_blog_des:hover .kode_blog_text h4 a,
.kode_client_wrap.client_3 .kode_client_text h6 a:hover,
.kode_philosophy_text:hover h4 a,
.kode_mosque_row.mosque figure a:hover,
/*home page 02 start */
.widget_event.event_2 .form-submit .medium_btn:hover,
.widget_event .kode_event_text h6 a:hover,
.kode_event_fig a:hover,
.kode_event_text span a:hover,
.kode_event_text span i,
.kode_calender_detail.detail_2 .kode_event_text h4 a:hover,
.kode_building_text h4 span,
.header .navigation ul li a:hover,
.kode_top2_icon ul li a span,
.kode_top2_icon ul li a,
.kode_top2_info li a:hover,
.kode_top2_info li span i,
/*home page start */
.kode_event_text h6 a:hover,
.top_btn:hover,
.kode_top_info ul li ul li a:hover,
.copyright_text p a,
.widget_call_info li a:hover,
.widget_call_info li a i,
.product_icon li a:hover,
.rating_star li a:hover,
.kode_product_text span:last-child,
.kode_event_text p span,
.koed_event_timer figcaption h5,
.kode_blog_fig .plus_icon,
.kode_meta.meta_2 a:hover, 
.kode_meta li a:hover,
.kode_blog_fig:hover .kode_blog_text h5,
.kode_meta li a i,
.kode_canvas_text h4,
.checkbox_radio label:focus,
.checkbox_radio:hover label,
.kode_gallery_fig a,
.kode_service_text  .right_arrow,
.kode_service_text h4 a:hover,
.kode_service_text span,
.kode_pillars_item li a span,
.kode_pillars_text span,
.kode_counter_mosque li h6 a:hover,
.kode_counter_mosque li span,
.section_hdg span,
.kode_top_right_text .kf_commet_field button,
.kode_top_info > ul > li > a:hover,
.highlight-hover:hover,
.kode_top_info ul li a i{
	color:#d2973b;
}

/*
	  ============================================================
		   Background Color
	  ============================================================
*/ 
.overlay ,
.kode_donors_list .slick-next:hover,
.kode_donors_list .slick-prev:hover,
.kode_gallery3_wrap .slick-prev:hover,
.kode_gallery3_wrap .slick-next:hover,
.kode_team_profile .event_detail_list li a:hover,
.kode_shop_fig  .shop_icon li a:hover,
.kode_side_contact_text a,
.kode_event_featured > a,
.kode_pricing_des:hover .medium_btn,
.kode_service_form  .form-submit .medium_btn,
.kode_attorney_detail_skill .label,
.kode_attorney_detail_skill .bar ,
.kode_project_text  > span,
.kode_portfolio_detail ul li a.active,
.kode_portfolio_detail ul li a:focus,
.kode_portfolio_detail ul li a:hover,
.kode_coming_event  .slick-dots li:hover,  
.kode_coming_event  .slick-dots li.slick-active,
.kode_event_detail li:hover .kode_event_ticket .medium_btn,
.kode_contact_service ul li:before,
.kode_contact_field .form-submit .medium_btn:hover,
.kode_coming_text .progress-bar ,
.kode_404_text h3,
.kode_comments .form-submit .medium_btn:hover,
.social_meta li a:hover,
.blog_quote span,
.kode_search .comment-form .kf_commet_field button:hover,
.kode_pagination li > a:hover,
.kode_blog_des:hover .them_overlay:before,
.kode_blog_des:hover .kode_blog_text .share_link,
.kode_client_wrap.client_3 .slick-prev:hover, 
.kode_client_wrap.client_3 .slick-next:hover,
.kode_Philosophy_wrap .slick-dots li:hover,
.kode_Philosophy_wrap .slick-dots li.slick-active,
.kode_philosophy_text:hover .small_btn ,
.kode_philosophy_text:hover span,
.kode_sab_banner_wrap.them_overlay:before,
/*home page 02 start */
.kode_mosque2_fig figure:after,
.widget_event.event_2 .kode_calender_list:hover > span,
.kode_banner_wrap.banner2 .slick-prev:hover, 
.kode_banner_wrap.banner2 .slick-next:hover,
.kode_navi_icon  li a:hover,
.kode_top2_icon .dropdown ul li a:hover,
/*home page start */
.btn_hover:hover:before,
.kode_amount_list span:hover,
.kode_pillars_item li a:hover,
.navigation ul li ul li a:hover:before,
.navigation > ul > li > a:hover:before,
.kode_calender_list:hover > span,
.top_btn,
.widget_social_icon li a:hover,
.kode_newsletter_wrap,
.kode_product_list > h5,
.kode_blog_fig:hover  .them_overlay:before,
.kode_team_wrap .slick-prev:hover,
.kode_team_wrap .slick-next:hover,
.kode_social_icon li a:hover,
.kode_gallery_fig:hover .them_overlay:before,
.kode_service_text  .right_arrow:hover,
.kode_service_des:hover .kode_service_text span,
.kode_mosque_fig figcaption h6,
.navigation ul li .active,
.kode_top_logo_wrap.them_overlay:before {
	background-color:#d2973b;
}
/*
	  ============================================================
		   Border Color
	  ============================================================
*/
.modal-content,
.navigation ul li ul,
.kode_donors_list .slick-next:hover,
.kode_donors_list .slick-prev:hover,
.kode_gallery3_wrap .slick-prev:hover,
.kode_gallery3_wrap .slick-next:hover,
.kode_pricing_des:hover .kode_pricing_dollar h3,
.kode_service_form  .kode_textarea textarea:focus,
.kode_service_form .kf_commet_field input:focus,
.hvr-ripple-out:hover:before, 
.hvr-ripple-out:focus:before, 
.hvr-ripple-out:active:before,
.kode_portfolio_detail ul li a:hover,
.widget_event .kode_textarea textarea:focus,
.siderbar_categories.archive .categories_detail  li:hover ,
textarea:focus,
input:focus,
.kode_comments .kode_textarea textarea:focus,
.blog_quote,
.kode_search .comment-form .kf_commet_field input:focus,
.sidebar_title:before,
.kode_search .comment-form .kf_commet_field button:hover,
.kode_blog_des:hover .kode_blog_text .share_link,
.kode_Philosophy_wrap .slick-dots,
/*home page 02 start */
.widget_event.event_2 .kode_textarea textarea:focus,
.kode_building_text .medium_btn:hover,
.kode_pillars_wrap.pillare_2 .kode_pillars_text:before,
.header .navigation ul ul,
.kode_banner_wrap.banner2 .slick-prev:hover, 
.kode_banner_wrap.banner2 .slick-next:hover,
/*home page start */
.comment-form button:hover,
.kode_amount_list span:hover,
.kode_pillars_item li a span,
.kode_top_right_text .kf_commet_field input:focus,
.navigation ul li ul li a:before,
.navigation ul li ul,
.kode_top_info ul li ul,
textarea:focus,
.widget_event .kf_commet_field input:focus,
.widget_event .form-submit .medium_btn:hover,
.widget_title:before,
.widget_social_icon li a:hover,
.kode_donation_row .kf_commet_field input:focus,
.kode_doantion_amount .kf_commet_field input:focus,
.kode_social_icon li a:hover,
.kode_service_text  .right_arrow:hover,
.kode_mosque_item .koed_banner_btn .medium_btn,
.kode_banner_text .medium_btn:hover,
.kode_top_icon ul li a:hover{
	border-color:#d2973b;
}
/*
	  ============================================================
		   Transaction Effect
	  ============================================================
*/
.kode_comments .form-submit .medium_btn,
.kode_pagination a,
.kode_text_list li a,
.kode_shop_text h6 a,
.event_rating a,
.kode_service_form  .form-submit .medium_btn,
.kode_project_text h5 a,
.kode_project_dollar  a,
.kode_project_text h4 a,
.kode_event_speaker_text h6 a,
.kode_coming_event  .slick-dots li  ,
.kode_coming_event  .slick-dots li.slick-active ,
.kode_coming_event_text a,
.kode_coming_event_text h5 a,
.kode_client_wrap.client_3 .kode_client_text h6 a,
.kode_client_wrap.client_3 .slick-prev, 
.kode_client_wrap.client_3 .slick-next,
.kode_Philosophy_wrap .slick-dots li,
.sab_banner_text a span,
/*home page 02 start */
.widget_event.event_2 .kode_calender_list > span,
.form-submit .medium_btn,
.widget_event .kode_event_text h6 a,
.kode_event_fig a,
.kode_event_text span a,
.kode_calender_detail.detail_2 .kode_event_text h4 a,
.kode_building_des .them_overlay:before,
.kode_mosque2_text .medium_btn,
.kode_banner_wrap.banner2 .slick-prev, 
.kode_banner_wrap.banner2 .slick-next,
.kode_navi_icon  li a,
.donate_btn  button.close,
.kode_top2_icon .dropdown ul li a,
.kode_top2_icon .dropdown ul,
.kode_top2_info li a,
/*home page start */
.comment-form button,
.kode_event_text h6 a,
.kode_pillars_item li a h6,
.kode_pillars_item li a,
.navigation ul li ul li a:before,
.top_icon_fig li a img,
.kode_calender_list > span,
.top_btn,
textarea,
input,
.widget_social_icon li a,
.widget_call_info li a,
.kode_product_list .slick-prev:before, 
.kode_product_list .slick-next:before,
.product_icon li a,
.rating_star li a,
.kode_blog_text,
.kode_blog_text h5,
.kode_blog_fig figure img,
.kode_blog_fig .plus_icon,
.kode_blog_fig  .them_overlay:before,
.kode_meta.meta_2 a ,
.kode_meta li a,
.kode_donation_row .kf_commet_field input,
.kode_doantion_amount .kf_commet_field input,
.form-submit .medium_btn,
.checkbox_radio label,
.kode_tem_fig figure img,
.kode_tem_fig figcaption,
.kode_team_wrap .slick-prev,
.kode_team_wrap .slick-next,
.kode_social_icon li a,
.kode_gallery_fig a,
.kode_gallery_fig .them_overlay:before,
.service_btn .medium_btn,
.kode_service_text  .right_arrow,
.kode_service_text span,
.kode_service_des,
.kode_service_text h4 a,
.kode_pillars_item li a span,
.kode_counter_mosque li h6 a,
.kode_mosque_item .koed_banner_btn .medium_btn,
.kode_mosque_fig .them_overlay:before,
.kode_mosque_fig figcaption,
.pager_link img,
.kode_banner_text .medium_btn,
.navigation ul li ul,
.navigation ul li a,
.kode_top_info ul li a
{
	transition:all 0.3s ease-in-out;
	-webkit-transition:all 0.3s ease-in-out;
	-moz-transition:all 0.3s ease-in-out;
}
.kode_search .comment-form .kf_commet_field input,
.kode_calender_list figure img,
.kode_blog_des figure img,
.koed_event_timer figure img,
.btn_hover:before,
.kode_client_text figure img{
	transition:all 1s ease-in-out;
	-webkit-transition:all 1s ease-in-out;
	-moz-transition:all 1s ease-in-out;
}
.kode_portfolio_des figure img,
.kode_blog_des figure img{
	transition:all 3s ease-in-out;
	-webkit-transition:all 3s ease-in-out;
	-moz-transition:all 3s ease-in-out;
}
.navigation .dl-menuwrapper > ul > li a,
.kode_blog_fig .them_overlay:before,
.top_time_circle ul li,
.kode_shop_fig,
.widget_event.event_2 .medium_btn,
.kode_newsletter_form .kf_commet_field .medium_btn,
.accordion-section h6 a:before,
.kode_photo_gallery .them_overlay:before,
.kode_photo_gallery li img,
.kode_donors_text .them_overlay:before,
.kode_donors_text h5 a,
.kode_donors_list .slick-prev:before,
.kode_donors_list .slick-next:before,
.kode_donors_list .slick-next,
.kode_donors_list .slick-prev,
.kode_project_detail_fig .them_overlay:before,
.kode_gallery3_wrap .slick-prev,
.kode_gallery3_wrap .slick-next,
.kode_gallery3_fig  figure img,
.kode_gallery3_fig .them_overlay:before,
.kode_team_profile .event_detail_list li a i,
.kode_team_profile .event_detail_list li a span,
.kode_team_profile .event_detail_list li a,
.kode_view_row .widget_social_icon li a,
.kode_team_fig figure img,
.kode_team_fig .them_overlay:before,
.kode_shop_fig  .shop_icon,
.kode_shop_fig .them_overlay:before,
.kode_shop_fig  .shop_icon li a,
.kode_side_contact_text a i,
.kode_service_des .right_arrow.btn,
.kode_pricing_dollar h3,
.kode_pricing_des,
.kode_pricing_des .medium_btn,
.kode_pricing_dollar,
.pricing_list li a,
.kode_portfolio_detail ul li a,
.kode_portfolio_text h4 a,
.kode_portfolio_des,
.kode_portfolio_text h6 a,
.kode_portfolio_des .them_overlay:before,
.kode_coming_fig .them_overlay:before,
.kode_event_speakar_fig,
.kode_event_speakar_fig .them_overlay:before,
.kode_event_ticket .medium_btn,
.kode_event_detail .kode_calender_list .them_overlay:before,
.kode_event_detail li,
.event_studium,
.kode_event_detail .kode_calender_list .kode_event_text span a,
.kode_event_detail .kode_calender_list .kode_event_text h4 a,
.kode_contact_text p,
.kode_contact_text > a,
.kode_contact_text h5,
.kode_contact_service ul li:before,
.kode_contact_field .form-submit .medium_btn,
.siderbar_categories.archive .categories_detail  li a:before,
.siderbar_categories.archive .categories_detail  li ,
.sidebar_add figure img,
.kode_comments .form-submit .medium_btn,
.comment_des .them_overlay:before,
.kode_social_share > a,
.social_meta li a,
.kode_detail_fig .them_overlay:before,
.kode_blog_detail_text h3 a,
.kode_blog_detail_des .them_overlay:before,
.kode_calender_list .them_overlay:before,
.categories_detail li a,
.kode_search .comment-form .kf_commet_field button,
.kode_blog_des,
.kode_pagination li a,
.kode_blog_des .them_overlay:before,
.kode_blog_des .expand_btn,
.kode_blog_des .kode_blog_text h4 a,
.kode_blog_des .kode_blog_text .share_link,
.kode_blog_des .kode_meta.meta_2 li a,
.kode_philosophy_text .small_btn ,
.kode_philosophy_text h4 a,
.kode_philosophy_text h4,
.kode_philosophy_text span,
.kode_philosophy_text,
.kode_mosque_row.mosque figure img, 
.kode_mosque_row.mosque figure a,
.kode_mosque2_fig figure img, 
.kode_mosque2_fig .them_overlay:before,
.kode_event_fig figure img,
.kode_building_des figure img,
.navigation > ul > li > a:before,
.kode_amount_list span,
.kode_gallery_fig figure img,
.kode_service_des figure img,
.kode_mosque_fig figure img,
.kode_client_text,
.kode_client_text .fig_hover,
.kode_client_text figure,
.btn_hover2:before,
.btn_hover:before{
	transition:all 0.5s ease-in-out;
	-webkit-transition:all 0.5s ease-in-out;
	-moz-transition:all 0.5s ease-in-out;
}
.theme_color_bg{
	background-color:#d2973b;	
}
.theme_text_color{
	color:#d2973b;
}
.bg_transparent{
	background:none;
}
.background-bg-dark{
	background-color:#333;
}


