.arabic {
  direction: rtl !important;
  font-family: 'Droid Arabic Naskh', serif !important;
}

.tamil {
  font-family: 'Noto Sans Tamil', sans-serif;
}

p {
  line-height: 2;
}
