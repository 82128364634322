.spreaker-toolbar {
  position: fixed;
  right: 10px;
  border-radius: 2px;
  box-shadow: 0 -5px 20px -10px grey;
  padding: 5px 10px;
  background-color: white;
}

.spreaker-toolbar.expanded {
  bottom: 300px;
}

.spreaker-toolbar.collapsed {
  bottom: 80px;
}

.spreaker-toolbar .fa {
  color: black;
  cursor: pointer;
}

.spreaker-toolbar .fa.fa-times {
  border-color: grey;
  border-left-style: solid;
  border-left-width: 1px;
  padding-left: 10px;
}

.spreaker-toolbar .move-up-down {
  padding-right: 7px;
}

.spreaker-holder {
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 1000;
  box-shadow: 0 -5px 20px -10px grey;
}