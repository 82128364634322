@font-face {
  font-family: 'icomoon';
  src:  url('/fonts/icomoon.eot?9guny2');
  src:  url('/fonts/icomoon.eot?9guny2#iefix') format('embedded-opentype'),
    url('/fonts/icomoon.ttf?9guny2') format('truetype'),
    url('/fonts/icomoon.woff?9guny2') format('woff'),
    url('/fonts/icomoon.svg?9guny2#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-monitor:before {
  content: "\e900";
}
.icon-web:before {
  content: "\e901";
}
.icon-technology-1:before {
  content: "\e902";
}
.icon-arrows-3 .path1:before {
  content: "\e903";
  color: rgb(199, 207, 226);
}
.icon-arrows-3 .path2:before {
  content: "\e904";
  margin-left: -1em;
  color: rgb(199, 207, 226);
}
.icon-arrows-3 .path3:before {
  content: "\e905";
  margin-left: -1em;
  color: rgb(230, 175, 120);
}
.icon-arrows-3 .path4:before {
  content: "\e906";
  margin-left: -1em;
  color: rgb(239, 242, 250);
}
.icon-arrows-3 .path5:before {
  content: "\e907";
  margin-left: -1em;
  color: rgb(210, 155, 110);
}
.icon-arrows-3 .path6:before {
  content: "\e908";
  margin-left: -1em;
  color: rgb(240, 192, 135);
}
.icon-arrows-3 .path7:before {
  content: "\e909";
  margin-left: -1em;
  color: rgb(230, 175, 120);
}
.icon-arrows-3 .path8:before {
  content: "\e90a";
  margin-left: -1em;
  color: rgb(228, 234, 246);
}
.icon-arrows-3 .path9:before {
  content: "\e90b";
  margin-left: -1em;
  color: rgb(228, 234, 246);
}
.icon-arrows-3 .path10:before {
  content: "\e90c";
  margin-left: -1em;
  color: rgb(91, 93, 110);
}
.icon-arrows-3 .path11:before {
  content: "\e90d";
  margin-left: -1em;
  color: rgb(81, 82, 98);
}
.icon-arrows-3 .path12:before {
  content: "\e90e";
  margin-left: -1em;
  color: rgb(228, 234, 246);
}
.icon-arrows-3 .path13:before {
  content: "\e90f";
  margin-left: -1em;
  color: rgb(228, 234, 246);
}
.icon-arrows-3 .path14:before {
  content: "\e910";
  margin-left: -1em;
  color: rgb(120, 85, 80);
}
.icon-arrows-3 .path15:before {
  content: "\e911";
  margin-left: -1em;
  color: rgb(240, 192, 135);
}
.icon-arrows-3 .path16:before {
  content: "\e912";
  margin-left: -1em;
  color: rgb(105, 75, 75);
}
.icon-arrows-3 .path17:before {
  content: "\e913";
  margin-left: -1em;
  color: rgb(90, 65, 70);
}
.icon-arrows-3 .path18:before {
  content: "\e914";
  margin-left: -1em;
  color: rgb(230, 175, 120);
}
.icon-arrows-3 .path19:before {
  content: "\e915";
  margin-left: -1em;
  color: rgb(228, 234, 246);
}
.icon-arrows-3 .path20:before {
  content: "\e916";
  margin-left: -1em;
  color: rgb(105, 75, 75);
}
.icon-arrows-3 .path21:before {
  content: "\e917";
  margin-left: -1em;
  color: rgb(90, 65, 70);
}
.icon-arrows-3 .path22:before {
  content: "\e918";
  margin-left: -1em;
  color: rgb(105, 75, 75);
}
.icon-arrows-3 .path23:before {
  content: "\e919";
  margin-left: -1em;
  color: rgb(120, 85, 80);
}
.icon-arrows-3 .path24:before {
  content: "\e91a";
  margin-left: -1em;
  color: rgb(230, 175, 120);
}
.icon-arrows-3 .path25:before {
  content: "\e91b";
  margin-left: -1em;
  color: rgb(180, 225, 250);
}
.icon-arrows-3 .path26:before {
  content: "\e91c";
  margin-left: -1em;
  color: rgb(210, 155, 110);
}
.icon-arrows-3 .path27:before {
  content: "\e91d";
  margin-left: -1em;
  color: rgb(160, 210, 240);
}
.icon-arrows-3 .path28:before {
  content: "\e91e";
  margin-left: -1em;
  color: rgb(160, 210, 240);
}
.icon-arrows-3 .path29:before {
  content: "\e91f";
  margin-left: -1em;
  color: rgb(160, 210, 240);
}
.icon-arrows-3 .path30:before {
  content: "\e920";
  margin-left: -1em;
  color: rgb(160, 210, 240);
}
.icon-arrows-3 .path31:before {
  content: "\e921";
  margin-left: -1em;
  color: rgb(160, 210, 240);
}
.icon-arrows-3 .path32:before {
  content: "\e922";
  margin-left: -1em;
  color: rgb(240, 192, 135);
}
.icon-arrows-3 .path33:before {
  content: "\e923";
  margin-left: -1em;
  color: rgb(230, 175, 120);
}
.icon-arrows-3 .path34:before {
  content: "\e924";
  margin-left: -1em;
  color: rgb(160, 210, 240);
}
.icon-arrows-3 .path35:before {
  content: "\e925";
  margin-left: -1em;
  color: rgb(199, 207, 226);
}
.icon-arrows-3 .path36:before {
  content: "\e926";
  margin-left: -1em;
  color: rgb(199, 207, 226);
}
.icon-education .path1:before {
  content: "\e927";
  color: rgb(43, 65, 77);
}
.icon-education .path2:before {
  content: "\e928";
  margin-left: -1em;
  color: rgb(71, 95, 108);
}
.icon-education .path3:before {
  content: "\e929";
  margin-left: -1em;
  color: rgb(43, 65, 77);
}
.icon-education .path4:before {
  content: "\e92a";
  margin-left: -1em;
  color: rgb(69, 94, 107);
}
.icon-education .path5:before {
  content: "\e92b";
  margin-left: -1em;
  color: rgb(75, 99, 112);
}
.icon-symbol .path1:before {
  content: "\e92c";
  color: rgb(20, 160, 133);
}
.icon-symbol .path2:before {
  content: "\e92d";
  margin-left: -1em;
  color: rgb(66, 74, 96);
}
.icon-close:before {
  content: "\e92e";
}
.icon-arrows-2:before {
  content: "\e92f";
}
.icon-circles:before {
  content: "\e930";
}
.icon-people-1:before {
  content: "\e931";
}
.icon-art:before {
  content: "\e932";
}
.icon-arrows-1:before {
  content: "\e933";
}
.icon-note:before {
  content: "\e934";
}
.icon-black:before {
  content: "\e935";
}
.icon-monuments-2:before {
  content: "\e936";
}
.icon-building:before {
  content: "\e937";
}
.icon-book-1:before {
  content: "\e938";
}
.icon-monuments-1:before {
  content: "\e939";
}
.icon-money:before {
  content: "\e93a";
}
.icon-interface:before {
  content: "\e93b";
}
.icon-halloween .path1:before {
  content: "\e93c";
  color: rgb(172, 171, 177);
}
.icon-halloween .path2:before {
  content: "\e93d";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-halloween .path3:before {
  content: "\e93e";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-halloween .path4:before {
  content: "\e93f";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-halloween .path5:before {
  content: "\e940";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-halloween .path6:before {
  content: "\e941";
  margin-left: -1em;
  color: rgb(106, 86, 61);
}
.icon-halloween .path7:before {
  content: "\e942";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-halloween .path8:before {
  content: "\e943";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-halloween .path9:before {
  content: "\e944";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-halloween .path10:before {
  content: "\e945";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-halloween .path11:before {
  content: "\e946";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-halloween .path12:before {
  content: "\e947";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-halloween .path13:before {
  content: "\e948";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-halloween .path14:before {
  content: "\e949";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-halloween .path15:before {
  content: "\e94a";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-halloween .path16:before {
  content: "\e94b";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-halloween .path17:before {
  content: "\e94c";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-halloween .path18:before {
  content: "\e94d";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-halloween .path19:before {
  content: "\e94e";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-halloween .path20:before {
  content: "\e94f";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-halloween .path21:before {
  content: "\e950";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-halloween .path22:before {
  content: "\e951";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-halloween .path23:before {
  content: "\e952";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-halloween .path24:before {
  content: "\e953";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-people:before {
  content: "\e954";
}
.icon-islam:before {
  content: "\e955";
}
.icon-social:before {
  content: "\e956";
}
.icon-book:before {
  content: "\e957";
}
.icon-monuments:before {
  content: "\e958";
}
.icon-arrows:before {
  content: "\e959";
}
.icon-play-1:before {
  content: "\e95a";
}
.icon-multimedia:before {
  content: "\e95b";
}
.icon-play:before {
  content: "\e95c";
}
.icon-commerce:before {
  content: "\e95d";
}
.icon-buildings:before {
  content: "\e95e";
}
.icon-technology:before {
  content: "\e95f";
}

