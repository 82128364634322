section{
	float: left;
	width: 100%;
	padding: 80px 0px;
}
*{
	box-sizing:border-box;
	-moz-box-sizing:border-box;
	-webkit-box-sizing:border-box;
	-o-box-sizing:border-box;
	-ms-box-sizing:border-box;	
}
body {
    color: #999999;
    font-family: 'Open Sans', sans-serif;
    font-size: 15px;
    font-weight: 300;
	line-height:24px;
    background-color: #ffffff;
}

.english {
    font-family: 'Open Sans', sans-serif !important;
}

body h1.english, body h2.english, body h3.english, body h4.english, body h5.english, body h6.english {
    font-family: 'Lato', sans-serif !important;
    font-style: normal;
}

/* Heading Typo */
body h1, body h2, body h3, body h4, body h5, body h6 {
    color: #333333;
    font-family: 'Lato', sans-serif;
    font-style: normal;
    font-weight: 300;
    margin: 0px 0px 0px 0px;
}

h1 {
    font-size: 50px;
}

h2{
    font-size: 42px;
}

h3 {
    font-size:30px;
}

h4 {
    font-size: 20px;
}

h5 {
    font-size: 18px;
}

h6 {
    font-size: 16px;
}
h1 a, h2 a, h3 a, h4 a, h5 a, h6 a {
    color: #2f2f2f;
	font-family: 'Poppins', sans-serif;
}
p a {
    color: #fff;
}
a {
    color: #777777;
    text-decoration: none;
}
a:hover, a:focus, a:active {
    outline: none;
    text-decoration: none;
}
ul {
   margin:0px;
   padding:0px;
}
li {
    
    list-style: none;
}
ul ul {
    margin:0px;
	padding:0px;
}
ol {
    float: none;
    list-style: decimal;
    padding-left: 15px;
}
ol ol {
    margin-left: 20px;
}
ol li {
	list-style: decimal;
	width: 100%;
}
figure {
	float:left;
	width:100%;
    position: relative;
	overflow:hidden;
	margin-bottom:0px;
}
.clear {
    clear: both;
    font-size: 0;
    line-height: 0;
}
figure img{
	float:left;
	width:100%;
	margin-bottom:0px;
}
img {
    max-width: 100%;
}

strong {
    color: #3d3d3d;
}

iframe {
    border: none;
    float: left;
    width: 100%;
}
/* Form Input Typo */
select {
    border: 1px solid #ececec;
    color: #999;
    cursor: pointer;
    display: inline-block;
    font-size: 14px;
    font-weight: 300;
    height: 50px;
    padding: 8px 12px;
    width: 100%;
}
label {
    color: #3d3d3d;
    display: block;
    font-weight: 400;
    margin-bottom: 10px;
}
button {
    border: none;
}
textarea, input[type="text"], 
input[type="password"], 
input[type="datetime"], 
input[type="datetime-local"], 
input[type="date"], 
input[type="month"],
input[type="time"], 
input[type="week"], 
input[type="number"], 
input[type="email"], 
input[type="url"], 
input[type="search"], 
input[type="tel"], 
input[type="color"], 
.uneditable-input {
    -moz-box-sizing: border-box;
  	-webkit-box-sizing: border-box;
	box-sizing: border-box;
    border: 1px solid #ececec;
    display: inline-block;
    font-size: 15px;
    font-weight: 400;
    line-height: 20px;
    outline: none;
    vertical-align: middle;
}
input{
	height:44px;
	padding:0px 15px;
	width:100%;
}
textarea{
	width:100%;
	height:160px;
	padding:15px 15px;
	resize:none;
}
form p {
    float: left;
    position: relative;
    width: 100%;
}
form p span i {
    color: #474747;
    left: 16px;
    position: absolute;
    top: 13px;
}
table {
    background-color: transparent;
    max-width: 100%;
    width: 100%;
}
th {
    text-align: left;
}
table > thead > tr > th, 
table > tbody > tr > th, 
table > tfoot > tr > th, 
table > thead > tr > td, 
table > tbody > tr > td, 
table > tfoot > tr > td {
    border-top: 1px solid #dddddd;
    border: 1px solid #dddddd;
    line-height: 2.5;
    padding-left: 7px;
    vertical-align: top;
}
table thead tr th {
    border-top: 1px solid #d2d2d2;
}
.table > caption + thead > tr:first-child > th, 
.table > colgroup + thead > tr:first-child > th, 
.table > thead:first-child > tr:first-child > th, 
.table > caption + thead > tr:first-child > td, 
.table > colgroup + thead > tr:first-child > td, 
.table > thead:first-child > tr:first-child > td {
	border-top: 1px solid #d2d2d2;
}
table > thead > tr > th {
    border-bottom: 2px solid #d2d2d2;
    vertical-align: bottom;
}
table > caption + thead > tr:first-child > th, 
table > colgroup + thead > tr:first-child > th, 
table > thead:first-child > tr:first-child > th, 
table > caption + thead > tr:first-child > td, 
table > colgroup + thead > tr:first-child > td, 
table > thead:first-child > tr:first-child > td 
{
	border-bottom: 0;
}
table > tbody + tbody {
    border-top: 2px solid #d2d2d2;
}
.table > thead > tr > th, .table > tbody > tr > th, 
.table > tfoot > tr > th, .table > thead > tr > td, 
.table > tbody > tr > td, .table > tfoot > tr > td {
    padding: 12px 16px;
}
p ins {
    color: #999;
}
dl dd {
    margin-left: 20px;
}
address {
    font-style: italic;
}
::-webkit-input-placeholder {color: #777777; opacity: 1;}
:-moz-placeholder { color: #777777; opacity: 1; }
::-moz-placeholder { color: #777777; opacity: 1; }
:-ms-input-placeholder {color: #777777; opacity: 1;}