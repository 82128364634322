.small_btn,
.medium_btn{
	display:inline-block;
	text-transform:uppercase;
	color:#fff;
	font-size:16px;
	padding:9px 20px 7px;
	line-height:normal;
	font-weight:600;
	font-family: 'Poppins', sans-serif;
	border-radius:2px;
}
.small_btn:hover,
.medium_btn:hover{
	color:#fff;
}

.small_btn{
	padding:8px 14px;
}
.border{
	border:1px solid #fff;
}
.margin-right{
	margin-right:15px;
}
.margin-right-1{
	margin-right:27px;
}
.margin{
	margin-bottom:40px;
}
/*main -heading start*/
.them_overlay{
	position:relative;
}
.btn_hover2:before,
.btn_hover:before,
.them_overlay:before{
	position:absolute;
	content:"";
	top:0px;
	left:0px;
	right:0px;
	bottom:0px;
	opacity:0.65;
	background-color:#000;
}
.btn_hover2,
.btn_hover{
	position:relative;
	z-index:9;
}
.btn_hover2:before,
.btn_hover:before{
	opacity:0;
	background:none;
	top:100%;
	z-index:-1;
}
.btn_hover2:hover:before,
.btn_hover:hover:before{
	opacity:1;
	top:0px;
}
.btn_hover2:hover:before{
	background-color:#000;
	opacity:0.9;
}
.sidebar_bg{
	background-color:#fbfbfb !important;
}
