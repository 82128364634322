.kode_pagination li a.active{
	color:#d2973b;
}

.kode_pagination li a.active:hover{
	color:#fff;
}

.kode_pagination li.page-info {
	color: black;
}

.kode_meta li i {
	margin-right: 14px;
}

.title-header.kode_Philosophy_wrap {
	padding: 40px 0px 20px !important;
}

.title-header h2 {
	margin-bottom: 10px;
}

p.tamil, p.arabic {
	color: #000;
}

#more-button.kode_pray_btn a {
	margin-top: 25px;
}

.category-posts ul.posts-list {
	margin-top: 30px;
}

div#page-content h3 {
	display: none;
}

#page-content {
	line-height: 2;
	color: #000;
}

div.tamil h3.header-title, div.tamil .item-text, div.tamil h6.featured-post-title, div.tamil .audio-item-container h3, div.tamil .audio-item-container h6 a {
	font-family: 'Noto Sans Tamil', sans-serif;
	font-weight: 100;
	line-height: 1.5;
}

.cursor-pointer:hover {
	cursor: pointer;
}

.fa.force-fa {
	font-family: 'FontAwesome' !important
}

.seekBase {
  height: 10px;
	width: 90%;
	background-color: #bbb8b8;
	cursor: pointer;
}
.seekLoad {
	height: 10px;
  width: 0;
  background-color: #d2973b;
}

.flex-container {
  display: flex;
}

.flex-container.player {
	flex-wrap: nowrap;
}

.flex-container.player a, .flex-container.player div {
	align-self: center;
}

.player #player-play-button {
	margin: 10px;
}

.flex-container.center-horizontal {
	justify-content: center;
}

.flex-container.center-vertical {
	align-items: center;
}

.player a {
	color: #d2973b;
}

.floating-player-container {
	position: fixed;
	bottom: 0;
	right: 50px;
	z-index: 100000;
	background: rgba(17,17,17,0.9);
	width: 350px;
}

.floating-player-container .controls {
	display: flex;
	height: 40px;
	align-items: center;
}

.floating-player-container .controls #player-play-button {
	flex-grow: 0;
	flex-shrink: 0;
	flex-basis: 44px;
	margin-left: 10px;
}

.floating-player-container .controls #player-stop-button {
	flex-grow: 0;
	flex-shrink: 0;
	flex-basis: 44px;
	margin-left: 10px;
}

.floating-player-container .controls i {
	color: white;
}

.pl-control-title {
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
	flex-grow: 1;
	flex-shrink: 1;
	flex-basis: auto;
}

.pl-control-title a {
	color: white;
	height: 40px;
}

@media (max-width: 430px) {
	.floating-player-container {
		right: 0px;
		width: 100%;
	}
	.floating-player-container .controls {
		height: 60px;
	}
}

.player-seek-base {
  height: 3px;
	width: 100%;
	color: inherit;
}

.player-seek-load {
	height: 3px;
  width: 0;
  background-color: #d2973b;
}

.audio-item-container img {
	width: 242px;
	height: 242px;
}

@media (max-width: 768px) {
	.audio-item-container figure {
		display: none;
	}
	.kode_building_text {
		padding-left: 0px;
	}
}

.title-header {
	background-size: auto !important;
}

.posts-list .list-item img, .post-items-list .list-item img {
	display: block;
	max-width: 70px;
	max-height: 70px;
	width: 70px;
	height: auto;
	border-radius: 2px;
}

.posts-list .post-data-container {
	margin-left: 30px;
}

.list-item {
	border-bottom: 1px solid #d5d5d5;
}

li:last-child .list-item {
	border-bottom: 0px;
}

.kode_blog_list > ul > li {
	margin-bottom: 0px;
}

@media (max-width: 768px) {
	.posts-list .list-item img {
		display: none;
	}
	.posts-list .post-data-container {
		margin-left: 0px;
	}
}

a.small_btn.btn_hover:focus {
	color: white;
}

/* nprogress color overrides */
#nprogress .bar {
  background: #d2973b;
}

/* Fancy blur effect */
#nprogress .peg {
  box-shadow: 0 0 10px #d2973b, 0 0 5px #d2973b;
}

#nprogress .spinner-icon {
  border-top-color: #d2973b;
  border-left-color: #d2973b;
}
/* end of nprogress color overrides */

.post-items-list .list-item h5.item-text {
	margin-left: 15px;
}

.post-items-list .list-item ul.kode_meta {
	margin-left: 55px;
}

.currently-listening {
	color: #d2973b;
}

div.root-container {
	padding-bottom: 100px;
}

@keyframes flickerAnimation {
  0%   { opacity:1; }
  50%  { opacity:0; }
  100% { opacity:1; }
}
@-o-keyframes flickerAnimation{
  0%   { opacity:1; }
  50%  { opacity:0; }
  100% { opacity:1; }
}
@-moz-keyframes flickerAnimation{
  0%   { opacity:1; }
  50%  { opacity:0; }
  100% { opacity:1; }
}
@-webkit-keyframes flickerAnimation{
  0%   { opacity:1; }
  50%  { opacity:0; }
  100% { opacity:1; }
}
.animate-flicker {
   -webkit-animation: flickerAnimation 1s infinite;
   -moz-animation: flickerAnimation 1s infinite;
   -o-animation: flickerAnimation 1s infinite;
    animation: flickerAnimation 1s infinite;
}

.arabic.objective {
	font-size: 3em;
	line-height: 1.5;
	color: white;
	font-family: 'Amiri', serif !important;
}

@media (max-width: 767px) {
	.arabic.objective {
		font-size: 2em;
	}
}

.kode_top_info ul li button i {
	color: #d2973b;
	margin-right: 5px;
}

.kode_top_info ul li ul {
	width: 200px !important;
}

@media (max-width: 991px) {
	.kode_top_info ul.top-links > li:nth-child(1) {
    display: none !important;
	}
	.kode_top_info ul.top-links > li:nth-child(2) {
    display: none !important;
	}
	.kode_top_info li:nth-child(1) {
    display: list-item;
	}
	.kode_top_info li:nth-child(2) {
    display: list-item;
	}
}

@media screen and (max-width: 480px) {
	.kode_top_info .open > .dropdown-menu {
    left: 0px !important;
	}
}

@media screen and (max-width: 767px) {
	.kode_blog_madium_wrap.detail.padding {
		padding-top: 10px !important;
	}
}

div.card {
	box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

div.card-rounded {
	box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  transition: 0.3s;
  border-radius: 5px; /* 5px rounded corners */
}

.categories_detail li:last-of-type {
	border-bottom: none;
}

.kode_portfolio_detail {
	margin-top: 20px;
}

.kode_portfolio_detail ul {
	margin-bottom: 0px;
}

.kode_portfolio_detail ul li {
	margin-right: 10px;
}

.kode_portfolio_detail ul li a{
	padding:8px 10px 10px;
	font-size:12px;
}
.kode_portfolio_detail ul li {
	margin-right: 4px;
}

.black, .black a {
	color: black;
}

.tags {
  list-style: none;
  margin: 0;
  overflow: hidden; 
	padding: 0;
	margin-bottom: 20px;
}

.tags li {
  float: left; 
}

.tag {
  background: #eee;
  border-radius: 3px 0 0 3px;
  color: #999;
  display: inline-block;
  height: 26px;
  line-height: 26px;
  padding: 0 20px 0 23px;
  position: relative;
  margin: 0 10px 10px 0;
  text-decoration: none;
	transition: color 0.2s;
	font-size: 0.8em;
}

.tag::before {
  background: #fff;
  border-radius: 10px;
  box-shadow: inset 0 1px rgba(0, 0, 0, 0.25);
  content: '';
  height: 6px;
  left: 10px;
  position: absolute;
  width: 6px;
  top: 10px;
}

.tag::after {
  background: #fff;
  border-bottom: 13px solid transparent;
  border-left: 10px solid #eee;
  border-top: 13px solid transparent;
  content: '';
  position: absolute;
  right: 0;
  top: 0;
}

.tag:hover {
  background-color: #d2973b;
  color: white;
}

.tag:hover::after {
   border-left-color: #d2973b; 
}

.kode_blog_wrap {
	padding-top: 40px;
}

.breadcrumbs li:before{
	position: absolute;
	content: "\f101";
	top: 1px;
	right: -23px;
	bottom: 0;
	font-family: fontawesome;
	font-size: 14px;
}

.breadcrumbs.ta li:before {
	top: 0px;
	bottom: 2px;
}

@media (min-width: 767px) {
	.breadcrumbs {
		padding: 0px;
	}
}

ul.category-tabs.nav {
	margin-top: 30px;
}

.audio-item-container h6 a {
	color: #d2973b;
}

.audio-item-container h6 a:hover {
	text-decoration: underline;
}

.audio-item-container h6 a i.fa {
	margin-right: 5px;
}

a.medium_btn i.fa {
	margin-right: 5px;
}

.audio-item-container .tag {
  background: #d2973b;
	color: white;
	margin-top: 20px;
}

.audio-item-container .tag::before {
  background: #333333;
}

.audio-item-container .tag::after {
  background: #333333;
	border-left: 10px solid #d2973b;
}

.audio-item-container .tag:hover {
  background-color: #D19644;
}

.tag:hover::after {
   border-left-color: #D19644; 
}

.kode_building_wrap.audio-item {
	height: 100vh;
}

.kode_building_wrap.video-item {
	height: 100%;
}

blockquote a {
	color: black;
}

h4.tamil {
	line-height: 1.5;
}

.text-center {
	text-align: center;
}

a.text-link {
	color: #D19644;
}

.no-content {
	margin-top: 100px;
}

.arabic.ltr {
	direction: ltr !important;
}

.header-card.sidebar-widget {
	margin-top: 70px;
}

.m-t-ten {
	margin-top: 10px;
}

.m-t-xl {
	margin-top: 30px;
}

.m-b-ten {
	margin-bottom: 10px;
}

.p-b-ten {
	padding-bottom: 10px;
}

@media (max-width: 991px) {
	.kode_pray_wrap div.col-md-4 {
		display: none;
	}
}

.kode_pray_text h5 {
	line-height: 1.5;
}

.position-relative {
	position: relative;
}

@media (min-width: 991px) {
	#search-container {
		width: 50%
	}
}

#search-container .card-rounded {
	border-radius: 50px;
	background-color: white;
}

#search-container .card-rounded input {
	border: 0px;
	border-radius: 50px;
	padding-left: 25px;
}

#search-container .card-rounded i.fa {
	padding-right: 25px;
	cursor: pointer;
}